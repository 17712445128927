.MainPageBannerV1-module__banner_S-IDLa__001 {
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 20px;
  display: flex;
}

.MainPageBannerV1-module__banner-content_S-IDLa__001 {
  background: #00000080;
  border-radius: 8px;
  max-width: 800px;
  padding: 20px;
}

.MainPageBannerV1-module__banner_S-IDLa__001 h1 {
  margin-bottom: 10px;
  font-size: 2.5rem;
}

.MainPageBannerV1-module__banner_S-IDLa__001 p {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.MainPageBannerV1-module__cta-button_S-IDLa__001 {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color .3s;
}

.MainPageBannerV1-module__cta-button_S-IDLa__001:hover {
  background-color: #0056b3;
}

@media (width <= 768px) {
  .MainPageBannerV1-module__banner_S-IDLa__001 h1 {
    font-size: 2rem;
  }

  .MainPageBannerV1-module__banner_S-IDLa__001 p {
    font-size: 1rem;
  }
}
